import './App.css';
import { loadStripe } from '@stripe/stripe-js';
import Wrapper from './components/Wrapper'
import { Elements } from '@stripe/react-stripe-js';
import React, { useState, useEffect } from 'react';
import { useMediaQuery } from '@react-hook/media-query';
import { Link } from 'react-router-dom'
import Footer from './components/Footer'

const stripePromise = loadStripe('pk_live_51Q0mpPAvc7OJE1HgrXR7hUfqHQT6vYzKOFhUyY5SOnGmCWzqyDNCNL4ZazSdmLlW2ypzidtPFySZ1P0CiLbCx4aK002N51ezBu');

function Bulk() {
  const [subscriptions, setSubscriptions] = useState([]);
  const [newName, setNewName] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [newAutopay, setNewAutopay] = useState(false);
  const [newDuration, setNewDuration] = useState('N/A');
  const [faqShown, setFaqShown] = useState(false)
  const [currency, setCurrency] = useState('€')
  const [prices] = useState({ 30: 14, 365: 144, 730: 240, 1095: 320 });
  const [AdobePrices] = useState({ 30: 59.99, 365: 659.88, 730: 659.88 * 2, 1095: 659.88 * 3 });
  const [totalPrice, setTotalPrice] = useState(0)
  const [totalAdobePrice, setTotalAdobePrice] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [isPaymentDone, setIsPaymentDone] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);
  const isMobile = useMediaQuery('(max-width: 668px)');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://extreme-ip-lookup.com/json/?key=uxJZ5TPQRYbhGqTVc6DI');
        const data = await response.json();
        const country = data.countryCode;
        setCurrency(country === 'US' ? '$' : '€');
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [currency]);

  useEffect(() => {
    let total = 0;
    let totaladobe = 0
    subscriptions.map((subscription) => (
      total += prices[subscription.duration]
    ))
    subscriptions.map((subscription) => (
      totaladobe += AdobePrices[subscription.duration]
    ))

    setTotalPrice(total);
    setTotalAdobePrice(totaladobe)

  }, [subscriptions, AdobePrices, prices]);

  const handleAddSubscription = () => {
    setSubscriptions([
      ...subscriptions,
      { name: newName, email: newEmail, duration: newDuration, autopay: newAutopay },
    ]);
    setNewName('');
    setNewEmail('');
    setNewAutopay(false)
    setNewDuration('N/A');
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {

      const agent = navigator.userAgent;
      await fetch('/.netlify/functions/create-payment-intent', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          browserAgent: agent,
          amount: totalPrice,
          name: subscriptions[0].name,
          email: subscriptions[0].email,
          duration: 0,
          currency: currency,
          bulk: true,
          orders: subscriptions,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          setClientSecret(data.clientSecret);
        });

    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const appearance = {
    theme: 'stripe',
    variables: {
      colorPrimary: '#1c53de'
    }
  };

  const options = { clientSecret, appearance, business: "ASM" }

  return (
    <div className="App">
      <div className='logo-div'>
        <img style={{ display: 'flex', width: '100%', justifyContent: 'center' }} draggable={false} src='/images/cheapcc.png' className='logo' alt='cheapcc-logo' />
        {/* <img alt='adobe-platinium-reseller-badge' src='/images/adobe-reseller.png' style={{ width:250, alignSelf:'center', justifySelf:'center' }} /> */}
        {/* <div className='links-div'>
          <Link to='/reviews'>See reviews</Link>
          <a href='https://adobeking.net'>Mirror website</a>
        </div> */}
        {/* <div className={`eta-div ${eta ? '' : 'loading'}`}><svg height="20" width="20" xmlns="http://www.w3.org/2000/svg"><circle r="5" cx="10" cy="10" fill={eta === '< 10 minutes' ? '#00bd55' : "#f5bd05"} /></svg><p style={{ margin: 0, color: 'black' }}>Delivered in {eta}</p></div> */}
      </div>
      <div className='main'>
        {(isMobile===false || isMobile==='false') && <div className='main-main'>
          <div className='main-main-main'>
            <form onSubmit={handleSubmit} className={`inputs ${!clientSecret ? "shown" : ''}`}>
              {!clientSecret  && <div className='inputs-subdiv'>
                <h1 style={{ marginBottom: 0, textAlign: 'left' }}>Create a group order</h1>
                <p>Included in our subcription is: all Adobe applications (except Substance 3D), 80GB cloud storage, Firefly AI with 250 credits renewed every month, access to beta apps, and 2 active simultaneous sessions (with 2 mobile sessions as well). You can access your suscription from anywhere on Earth, no VPN is needed.</p>
                <div className='input-container'>
                  <div className='input-div' style={{ flexDirection: 'column' }}>
                    <p style={{ fontSize: 14, color: '#a6a6a6', display: 'flex', flexDirection: 'row', gap: 7, alignItems: 'center', width: 'fit-content' }}><img alt='info-icon' draggable={false} height={18} src='/images/info.svg' />The first user of the list is the payer.</p>
                    <table>
                      <thead>
                        <tr>
                          <th>Full name</th>
                          <th>E-mail address</th>
                          <th style={{ minWidth: 87 }}>Auto-pay</th>
                          <th>Duration</th>
                        </tr>
                      </thead>
                      <tbody>
                        {subscriptions.map((subscription, index) => (
                          <tr key={index}>
                            <td>{subscription.name}</td>
                            <td>{subscription.email}</td>
                            <td><input type='checkbox' defaultChecked={subscription.autopay} onChange={() => (setSubscriptions([...subscriptions.slice(0, index), { name: subscription.name, email: subscription.email, duration: subscription.duration, autopay: !subscription.autopay }, ...subscriptions.slice(index + 1)]))} /></td>
                            <td>{subscription.duration === '30' ? '1 month' : (subscription.duration === '365' ? '1 year' : (subscription.duration === '730' ? '2 years' : subscription.duration === '1095' ? '3 years' : '??'))}</td>
                            <td style={{ cursor: 'pointer', backgroundColor: '#d43f3f', color: 'white', fontFamily: 'Arial', fontWeight: 'bold' }} onClick={() => (setSubscriptions([...subscriptions.slice(0, index), ...subscriptions.slice(index + 1)]))}>X</td>
                          </tr>
                        ))}
                        <tr className='input-tr'>
                          <td className='input-td' style={{ borderRightWidth: 2 }}>
                            <input
                              type="text"
                              className="w-full"
                              placeholder="Name"
                              value={newName}
                              onChange={(e) => setNewName(e.target.value)}
                            />
                          </td>
                          <td className='input-td'>
                            <input
                              type="email"
                              className="w-full"
                              placeholder="Email"
                              value={newEmail}
                              onChange={(e) => setNewEmail(e.target.value)}
                            />
                          </td>
                          <td className='input-td' style={{ height: 40, border: 0 }}>
                            <input
                              type="checkbox"
                              className="w-full"
                              checked={newAutopay}
                              onChange={() => setNewAutopay(!newAutopay)}
                            />
                          </td>
                          <td className='input-td'>
                            <select
                              className="w-full"
                              value={newDuration}
                              onChange={(e) => setNewDuration(e.target.value)}
                              defaultValue={'N/A'}
                              style={{ width: '100%', border: '0', minWidth: '100px' }}
                            >
                              <option disabled value='N/A'>Select duration</option>
                              <option value={30}>1 month</option>
                              <option value={365}>1 year</option>
                              <option value={730}>2 years</option>
                              <option value={1095}>3 years</option>
                            </select>
                          </td>
                          <td style={{ padding: 0, minWidth: 30, backgroundColor: '#c2c9d1' }}><button style={{ padding: 0, borderRadius: 0, width: '100%', height: 40, fontSize: 22, fontWeight: 'bold', fontFamily: 'Arial' }} disabled={newDuration === 'N/A' || newEmail === '' || newEmail.includes('@') === false || newName === ''} disa onClick={handleAddSubscription}>+</button></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div>
                  <h1 style={{ textWrap: 'nowrap', width: 'fit-content' }}><span style={{ fontSize: 24 }}>{currency}</span> {totalPrice.toFixed(2)}</h1>
                  {totalPrice !== 0 && <p style={{ color: 'black' }}>You save <b>{currency}{(totalAdobePrice-totalPrice).toFixed(2)}</b> compared to adobe.com prices.</p>}
                </div>
                <div className='pay-button-div'>
                  <button disabled={subscriptions.length === 0} type="submit"><span id="button-text">
                    {!clientSecret  && isLoading === true ? <div className="spinner" id="spinner"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" width={25} height={25} style={{ shapeRendering: 'auto', display: 'block', background: 'transparent', width: '21.5', height: '21.5', paddingLeft: 11, paddingRight: 11, paddingTop: -2, paddingBottom: -2 }}><g><circle strokeDasharray="136.659280431156 47.553093477052" r="29" strokeWidth="10" stroke="#ffffff" fill="none" cy="50" cx="50"><animateTransform keyTimes="0;1" values="0 50 50;360 50 50" dur="1.3513513513513513s" repeatCount="indefinite" type="rotate" attributeName="transform"></animateTransform></circle><g></g></g></svg></div> : "Select"}</span></button>
                  <p style={{ margin: 0, color:'black', fontSize:14 }}>Want an individual subscription? <Link style={{ color:'black', fontSize:14, outline:'none' }} to='/'>Click here</Link></p>
                </div>
                {/* <div>
              <p>Once you've filled this form and paid, you'll receive a "team invitation" email from Adobe, inviting you to join a school team subscription.</p>
              <p>Simply accept it, and you now have access to the whole Creative Cloud suite, with <b>all Adobe applications</b>.</p>
            </div> */}
              </div>}
            </form>
            <div className={`payment-container ${(isPaymentDone === false && clientSecret) ? "shown" : ''}`}>
              {(isPaymentDone === false && clientSecret) && <Elements stripe={stripePromise} options={options}>
                <button onClick={() => (setClientSecret(null))} className='return-button'><span style={{ transform: 'translateY(-1px)', fontSize: 16 }}>←</span>Return to home</button>
                <Wrapper currency={currency} priceId={null} autoPay={false} name={subscriptions[0].name} email={subscriptions[0].email} duration={null} prices={prices} isPaymentDone={isPaymentDone} setIsPaymentDone={setIsPaymentDone} clientSecret={clientSecret} totalPrice={totalPrice} bulk={true} orders={subscriptions} />
              </Elements>}
            </div>
            <div className={`payment-container-done ${isPaymentDone === true && isLoading === false ? "shown" : ''}`}><div className='success-div'><div class="animation-ctn">
              <div class="icon icon--order-success svg">
                <svg xmlns="http://www.w3.org/2000/svg" width="154px" height="154px">
                  <g fill="none" stroke="#22AE73" stroke-width="2">
                    <circle cx="77" cy="77" r="72" style={{ strokeDasharray: '480px, 480px', strokeDashOffset: 960 }}></circle>
                    <circle id="colored" fill="#22AE73" cx="77" cy="77" r="72" style={{ strokeDasharray: '480px, 480px', strokeDashOffset: 960 }}></circle>
                    <polyline class="st0" stroke="#fff" stroke-width="10" points="43.5,77.8 63.7,97.9 112.2,49.4 " style={{ strokeDasharray: '100px, 100px', strokeDashOffset: 200 }} />
                  </g>
                </svg>
              </div>
            </div>{isPaymentDone === true && isLoading === false && <h2 style={{ textAlign: 'center', marginTop: 60 }}>Payment successful.</h2>}{isPaymentDone === true && isLoading === false && <p style={{ textAlign: 'center', marginTop: 60 }}>You should receive your invitation email within 24 hours.</p>}{isPaymentDone === true && isLoading === false && <p style={{ textAlign: 'center', marginTop: 10 }}>If any problem, contact us on Telegram:</p>}{isPaymentDone === true && isLoading === false && <h3 style={{ textAlign: 'center', marginTop: 20 }}>@cheapccnet</h3>}</div></div>
          </div>
          <div className={`faq ${faqShown ? 'shown' : ''}`}>
            <div onClick={() => (setFaqShown(!faqShown))} style={{ cursor: 'pointer', display: 'flex', flexDirection: 'row', width: '100%', alignItems: faqShown ? 'flex-start' : 'center', justifyContent: 'space-between' }}>
              <h1 className={`faq-title ${faqShown ? 'withMargin' : ''}`}>FAQ</h1>
              <svg style={{ transition: '0.1s', transform: faqShown ? 'rotate(90deg)' : '' }} fill="#000000" height="30px" width="30px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 330 330">
                <path id="XMLID_222_" d="M250.606,154.389l-150-149.996c-5.857-5.858-15.355-5.858-21.213,0.001
                c-5.857,5.858-5.857,15.355,0.001,21.213l139.393,139.39L79.393,304.394c-5.857,5.858-5.857,15.355,0.001,21.213
                C82.322,328.536,86.161,330,90,330s7.678-1.464,10.607-4.394l149.999-150.004c2.814-2.813,4.394-6.628,4.394-10.606
                C255,161.018,253.42,157.202,250.606,154.389z"/>
              </svg>
            </div>
            <div className={`faq-text ${faqShown ? 'shown' : ''}`}>
              <h3 style={{ textAlign: 'left' }}>{faqShown ? "What is CheapCC.net?" : ""}</h3>
              <p>{faqShown ? "CheapCC.net is a site where you can legally get the genuine Adobe CC All Apps plan applied to your own Adobe account, at a much lower price." : ""}</p>
              <h3 style={{ textAlign: 'left', marginTop: 20 }}>{faqShown ? "Where does the subscription come from?" : ''}</h3>
              <p style={{ marginBottom: 5 }}>{faqShown ? "In an attempt to gain a foothold in China, Adobe is giving away almost free subscriptions to resellers for distribution to individuals and professionals. Many administrators have started trying to resell them to Westerners. But because they're in China, they have an embargo with many Western countries, so it's very complicated. Most of the time, they can only sell on Chinese marketplaces that are difficult to access from our Western countries." : ""}</p>
              <p style={{ marginBottom: 5 }}>{faqShown ? "CheapCC is in contact with the best resellers in China, and acts as a secure gateway hosted in the US, offering the best possible prices on the most stable subscriptions on the market. " : ""}<b>All our suppliers are Adobe-certified Gold or Platinum resellers.</b></p>
              <h3 style={{ textAlign: 'left', marginTop: 20 }}>{faqShown ? "Can my subscription be revoked by Adobe?" : ""}</h3>
              <p>{faqShown ? "The subscriptions we have are geo-unlocked. It means they're not like the Turkish ones that you have to pay with a VPN. It means they can legally be used from anywhere in the world, without violating any terms of service or conditions of use." : ""}</p>
              <h3 style={{ textAlign: 'left', marginTop: 20 }}>{faqShown ? "Can my subscription be cancelled?" : ""}</h3>
              <p>{faqShown ? "Our resellers have been around for years and are well known in the industry. They guarantee the full duration for which you paid. Our support team is available daily to address any inquiries or issues, providing replacement subscriptions or refunds if necessary." : ""}</p>
              {faqShown && <Link style={{ textAlign: 'left', display: 'flex', marginLeft: 10, width: '100%', color: '#596B76' }} to='/refund-policy'>See our refund policy</Link>}
              <h3 style={{ textAlign: 'left', marginTop: 20 }}>{faqShown ? "Does anyone has access to my content?" : ""}</h3>
              <p>{faqShown ? "We DO NOT have access to your content created in the Adobe applications. Only Adobe has potential access, particularly when using online features, such as generative AI. Here's an article about the recent updates to Adobe's terms of use on their access to user content:" : ""}</p>
              {faqShown && <a style={{ textAlign: 'left', display: 'flex', marginLeft: 10, width: '100%', color: '#596B76' }} href='https://blog.adobe.com/en/publish/2024/06/06/clarification-adobe-terms-of-use'>blog.adobe.com/en/publish/2024/06/06/clarification-adobe-terms-of-use</a>}
            </div>
          </div>
        </div>}
        {(isMobile==='true' || isMobile===true) && <div className='main-main-main' style={{ display:'flex', flexDirection:'column', gap:30, backgroundColor:'white', borderRadius:'8px', width:'calc(100% - 80px)', padding:20 }}>
          <h2 style={{ width:'100%', textAlign:'center' }}>Bulk ordering is only available on desktop.</h2>
          <Link style={{ color:'black', fontSize:18, width:'100%', textAlign:'center' }} to='/'>Order an individual subscription</Link>
          </div>}
        <Footer />
      </div>
    </div>
  );
}

export default Bulk;
