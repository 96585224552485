import { Route, Routes, Navigate } from "react-router-dom"
import React from "react";
import Main from "./Main";
import Refund from "./Refund";
import TermsOfService from "./TermsOfService";
import PrivacyNotice from "./PrivacyNotice";
import Bulk from "./Bulk";
import Success from "./Success";

export default function App() {
  return (
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="/bulk" element={<Bulk />} />
      <Route path="/success" element={<Success />} />
      <Route path="/refund-policy" element={<Refund />} />
      <Route path="/refund" element={<Refund />} />
      <Route path="/privacy-notice" element={<PrivacyNotice />} />
      <Route path="/terms-of-service" element={<TermsOfService />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}