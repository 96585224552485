import './App.css';
import { loadStripe } from '@stripe/stripe-js';
import Wrapper from './components/Wrapper'
import { Elements } from '@stripe/react-stripe-js';
import React, { useState, useRef, useEffect } from 'react';
import { useMediaQuery } from '@react-hook/media-query';
import { Link } from 'react-router-dom'
import Footer from './components/Footer'
import Logo from './images/cheapcclogo.avif'

const stripePromise = loadStripe('pk_live_51Q0mpPAvc7OJE1HgrXR7hUfqHQT6vYzKOFhUyY5SOnGmCWzqyDNCNL4ZazSdmLlW2ypzidtPFySZ1P0CiLbCx4aK002N51ezBu');

function Main() {
  const [faqShown, setFaqShown] = useState(false)
  const [currency, setCurrency] = useState('€')
  const [prices] = useState({ 30: 14, 365: 144, 730: 240, 1095: 320 });
  const [AdobePrices] = useState({ 30: 59.99, 365: 659.88, 730: 659.88 * 2, 1095: 659.88 * 3 });
  const [name, setName] = useState('');
  const [isAutoPay, setIsAutoPay] = useState(false);
  const [email, setEmail] = useState(null);
  const [priceData, setpriceData] = useState({
    30: currency === '€' ? 'price_1Q0nKkAvc7OJE1HgnItk70ko' : 'price_1Q1ojBAvc7OJE1Hg5OQoXyDD',
    365: currency === '€' ? 'price_1Q0nLIAvc7OJE1HgEv3rHARq' : 'price_1Q1oiuAvc7OJE1HgnEXnNZqw',
    730: currency === '€' ? 'price_1Q0nMSAvc7OJE1HgtkmQqjAN' : 'price_1Q1oiPAvc7OJE1HgaFePnPAm',
    1095: currency === '€' ? 'price_1Q0nN2Avc7OJE1HgYFajWIaO' : 'price_1Q1ohsAvc7OJE1Hg9A9ltmAz',
  })
  // const [eta, setEta] = useState(null)
  const [duration, setDuration] = useState(30);
  const [isLoading, setIsLoading] = useState(false)
  const [isPaymentDone, setIsPaymentDone] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  // const [isHovered2, setIsHovered2] = useState(false);
  const [formSubmittedAutoPay, setFormSubmittedAutoPay] = useState(false);
  const isMobile = useMediaQuery('(max-width: 668px)');

  // useEffect(() => {
  //   const date = new Date();
  //   const options = {
  //     timeZone: 'Asia/Shanghai',
  //     hour: 'numeric',
  //     minute: 'numeric',
  //     second: 'numeric',
  //   };
  //   const shanghaiTime = new Intl.DateTimeFormat('en-US', options).format(date);
  //   const hour = parseInt(shanghaiTime.split(':')[0], 10);

  //   if (hour >= 6 && hour <= 23) {
  //     setEta('< 10 minutes');
  //   } else {
  //     setEta('< 6 hours');
  //   }
  // }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://extreme-ip-lookup.com/json/?key=uxJZ5TPQRYbhGqTVc6DI');
        const data = await response.json();
        const country = data.countryCode;
        setCurrency(country === 'US' ? '$' : '€');
        setpriceData({
          30: country === 'US' ? 'price_1Q1ojBAvc7OJE1Hg5OQoXyDD' : 'price_1Q0nKkAvc7OJE1HgnItk70ko',
          365: country === 'US' ? 'price_1Q1oiuAvc7OJE1HgnEXnNZqw' : 'price_1Q0nLIAvc7OJE1HgEv3rHARq',
          730: country === 'US' ? 'price_1Q1oiPAvc7OJE1HgaFePnPAm' : 'price_1Q0nMSAvc7OJE1HgtkmQqjAN',
          1095: country === 'US' ? 'price_1Q1ohsAvc7OJE1Hg9A9ltmAz' : 'price_1Q0nN2Avc7OJE1HgYFajWIaO',
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [currency]);

  useEffect(() => {
    if ((isMobile === true || isMobile === 'true') && (isPaymentDone===true || isPaymentDone==='true')) {
      const { top } = mobileMain.current.getBoundingClientRect();
      window.scrollTo({ top: top + document.documentElement.scrollTop - 15, behavior: 'smooth' });
    }
  }, [isPaymentDone, isMobile]);

  const svgRef = useRef(null);
  const mobileMain = useRef(null);
  // const svgRef2 = useRef(null);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const returnToHome = () => {
    if (isAutoPay === true || isAutoPay === 'true') {
      setFormSubmittedAutoPay(false);
    }
    if (isAutoPay === false || isAutoPay === 'false') {
      setClientSecret(null);
    }
    if (isMobile === true || isMobile === 'true') {
      const { top } = mobileMain.current.getBoundingClientRect();
      window.scrollTo({ top: top + document.documentElement.scrollTop - 15, behavior: 'smooth' });
    }
  }

  // const handleMouseEnter2 = () => {
  //   setIsHovered2(true);
  // };

  // const handleMouseLeave2 = () => {
  //   setIsHovered2(false);
  // };

  const getSvgPosition = () => {
    if (svgRef.current) {
      const { x, y, width } = svgRef.current.getBoundingClientRect();
      return { top: y - 20, left: isMobile ? (x - width - 50) : (x - width + 50) };
    }
    return { top: 0, left: 0 };
  };

  // const getSvgPosition2 = () => {
  //   if (svgRef2.current) {
  //     const { x, y, width } = svgRef2.current.getBoundingClientRect();
  //     return { top: y - 20, left: isMobile?(x-width-50):(x - width + 50) };
  //   }
  //   return { top: 0, left: 0 };
  // };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {

      const agent = navigator.userAgent;

      if (isAutoPay === false || isAutoPay === 'false') {
        await fetch('/.netlify/functions/create-payment-intent', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            browserAgent: agent,
            amount: prices[duration],
            name: name,
            email: email,
            duration: duration,
            currency: currency,
            bulk: false,
            orders: null,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            setClientSecret(data.clientSecret);
          });
      }
      if (isAutoPay === true || isAutoPay === 'true') {
        setFormSubmittedAutoPay(true)
      }

    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const appearance = {
    theme: 'stripe',
    variables: {
      colorPrimary: '#1c53de'
    }
  };

  const options = isAutoPay === false ? { clientSecret, appearance, business: "ASM" } : { mode: 'setup', currency: currency === '$' ? 'usd' : 'eur', appearance, business: "ASM" }

  return (
    <div className="App">
      <div className='logo-div'>
        <img style={{ display: 'flex', width: '100%', justifyContent: 'center' }} draggable={false} src={Logo} className='logo' alt='cheapcc-logo' />
      </div>
      {(isMobile === 'false' || isMobile === false) && <div className='main'>
        {isHovered === true && (<div className='question-div' style={{ position: 'fixed', ...getSvgPosition(), zIndex: 999, width: '230px', backgroundColor: 'black', color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>If disabled, your subscription will be revoked at the end of the first period. If enabled, renewal will be automatic.<br></br>To cancel auto-pay, simply contact us.</div>)}
        {/* {isHovered2 === true && (<div className='question-div' style={{ position: 'fixed', ...getSvgPosition2(), zIndex: 999, width: '230px', backgroundColor: 'black', color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>It doesn't matter if your e-mail address is associated or not with an Adobe account when you buy your subscription on CheapAdobe.com. You can create your Adode account after your purchase.</div>)} */}
        <div className='main-main'>
          <div className='main-main-main'>
            <form onSubmit={handleSubmit} className={`inputs ${!clientSecret && formSubmittedAutoPay === false ? "shown" : ''}`}>
              {!clientSecret && formSubmittedAutoPay === false && <div className='inputs-subdiv'>
                <h1 style={{ marginBottom: 0, textAlign: 'left' }}>Create an order</h1>
                <p>You get the genuine Adobe CC All Apps plan applied to your own Adobe account. Included in our subcription is: all Adobe applications (except Substance 3D), 80GB cloud storage, Firefly AI with 250 credits renewed every month, access to beta apps, and 2 active simultaneous sessions (with 2 mobile sessions as well). It's the authentic and official subscription, no need to use a VPN.</p>
                <div className='input-container'>
                  <div className='input-div'>
                    <label>Full name:</label>
                    <div className='input-wrapper' style={{ maxWidth: 'calc(100% - 40px)', minWidth: 280 }} ><input style={{ width: '-webkit-fill-available' }} value={name} spellCheck={false} required onChange={(e) => (setName(e.target.value))} placeholder='John Smith' type='name' /></div>
                  </div>
                  <div className='input-div'>
                    <label style={{ textAlign: 'left', gap: 10 }}>Adobe account email address:</label>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                      <div className='input-wrapper' style={{ maxWidth: 'calc(100% - 40px)', minWidth: 280 }} ><input style={{ width: '-webkit-fill-available' }} value={email} spellCheck={false} required onChange={(e) => (setEmail(e.target.value))} placeholder='john.smith@gmail.com' type='email' /></div>
                    </div>
                  </div>
                  <div className='input-div'>
                    <label>Duration of your subscription:</label>
                    <select value={duration} onChange={(e) => (setDuration(parseFloat(e.target.value)))}>
                      <option disabled>Want a 3-days free trial? Contact us.</option>
                      <option value={30}>1 month</option>
                      <option value={365}>1 year</option>
                      <option value={730}>2 years</option>
                      <option value={1095}>3 years</option>
                    </select>
                  </div>
                  <div className='input-div'>
                    <label style={{ textAlign: 'left', display: 'flex', alignItems: 'center', gap: 10 }}>Enable auto-pay:<svg ref={svgRef} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} style={{ cursor: 'pointer' }} fill="black" height="20" viewBox="0 0 15 15" width="20" xmlns="http://www.w3.org/2000/svg"><g fill="#black"><path d="m6.74984 10.5c0 .4142.33579.75.75.75.41422 0 .75-.3358.75-.75s-.33578-.74999-.75-.74999c-.41421 0-.75.33579-.75.74999z" /><path d="m8.94995 6.25001c0-.67789-.58507-1.325-1.45-1.325-.86492 0-1.45.64711-1.45 1.325 0 .30376-.24624.55-.55.55s-.55-.24624-.55-.55c0-1.32211 1.11493-2.425 2.55-2.425 1.43508 0 2.55005 1.10289 2.55005 2.425 0 1.10382-.73004 1.64038-1.26449 1.96506-.10264.06235-.19141.1138-.26993.1593-.15058.08727-.26359.15276-.36509.23184-.06564.05115-.09133.08341-.10001.0971l-.00054.00087c-.00224.30184-.24762.54583-.54999.54583-.30375 0-.55-.24624-.55-.55 0-.46802.28211-.77268.52446-.96151.16338-.1273.38078-.25355.56398-.35994.0641-.03723.12404-.07204.176-.10361.46555-.28282.73556-.53376.73556-1.02494z" /><path clipRule="evenodd" d="m14 7.5c0 3.5899-2.9101 6.5-6.5 6.5-3.58985 0-6.5-2.9101-6.5-6.5 0-3.58985 2.91015-6.5 6.5-6.5 3.5899 0 6.5 2.91015 6.5 6.5zm-1 0c0 3.0376-2.4624 5.5-5.5 5.5-3.03757 0-5.5-2.4624-5.5-5.5 0-3.03757 2.46243-5.5 5.5-5.5 3.0376 0 5.5 2.46243 5.5 5.5z" fillRule="evenodd" /></g></svg></label>
                    <select value={isAutoPay} onChange={(e) => (setIsAutoPay(e.target.value === 'true' || e.target.value === true))}>
                      <option value={true}>Yes (free)</option>
                      <option value={false}>No, thanks</option>
                    </select>
                  </div>
                </div>
                <div>
                  <h1 style={{ textWrap: 'nowrap', width: 'fit-content' }}><span style={{ fontSize: 24 }}>{currency}</span> {prices[duration]}.00 {duration !== 30 && <span style={{ fontSize: 14, fontWeight: 400 }}>(= {currency} {duration === 30 ? 14 : (duration === 365 ? 12 : (duration === 730 ? 10 : (duration === 1095 ? 9 : '???')))}.00 /month)</span>}</h1>
                  <p style={{ color: 'black' }}>You save <b>{currency}{(AdobePrices[duration] - prices[duration]).toFixed(2)}</b> compared to Adobe.com prices.</p>
                </div>
                <div className='pay-button-div'>
                  <button disabled={(!email || !name)} type="submit"><span id="button-text">
                    {!clientSecret && formSubmittedAutoPay === false && isLoading === true ? <div className="spinner" id="spinner"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" width={25} height={25} style={{ shapeRendering: 'auto', display: 'block', background: 'transparent', width: '21.5', height: '21.5', paddingLeft: 11, paddingRight: 11, paddingTop: -2, paddingBottom: -2 }}><g><circle strokeDasharray="136.659280431156 47.553093477052" r="29" strokeWidth="10" stroke="#ffffff" fill="none" cy="50" cx="50"><animateTransform keyTimes="0;1" values="0 50 50;360 50 50" dur="1.3513513513513513s" repeatCount="indefinite" type="rotate" attributeName="transform"></animateTransform></circle><g></g></g></svg></div> : "Select"}</span></button>
                  <p style={{ margin: 0, color: 'black', fontSize: 14 }}>Want multiple subscriptions? <Link style={{ color: 'black', fontSize: 14, outline: 'none' }} to='/bulk'>Click here</Link></p>
                </div>
                {/* <div>
              <p>Once you've filled this form and paid, you'll receive a "team invitation" email from Adobe, inviting you to join a school team subscription.</p>
              <p>Simply accept it, and you now have access to the whole Creative Cloud suite, with <b>all Adobe applications</b>.</p>
            </div> */}
              </div>}
            </form>
            <div className={`payment-container ${(isPaymentDone === false && clientSecret) || (formSubmittedAutoPay === true && isPaymentDone === false) ? "shown" : ''}`}>
              {(isPaymentDone === false && clientSecret && isAutoPay === false) && <Elements stripe={stripePromise} options={options}>
                <button onClick={() => (setClientSecret(null))} className='return-button'><span style={{ transform: 'translateY(-1px)', fontSize: 16 }}>←</span>Return to home</button>
                <Wrapper currency={currency} priceId={priceData[duration]} autoPay={isAutoPay} name={name} email={email} duration={duration} prices={prices} isPaymentDone={isPaymentDone} setIsPaymentDone={setIsPaymentDone} clientSecret={clientSecret} bulk={false} bulk_data={null} />
              </Elements>}
              {(isPaymentDone === false && isAutoPay === true && formSubmittedAutoPay === true) && <Elements stripe={stripePromise} options={options}>
                <button onClick={() => (setFormSubmittedAutoPay(false))} className='return-button'><span style={{ transform: 'translateY(-1px)', fontSize: 16 }}>←</span>Return to home</button>
                <Wrapper currency={currency} priceId={priceData[duration]} autoPay={isAutoPay} name={name} email={email} duration={duration} prices={prices} isPaymentDone={isPaymentDone} setIsPaymentDone={setIsPaymentDone} bulk={false} bulk_data={null} />
              </Elements>}
            </div>
            <div className={`payment-container-done ${isPaymentDone === true && isLoading === false ? "shown" : ''}`}><div className='success-div'><div class="animation-ctn">
              <div class="icon icon--order-success svg">
                <svg xmlns="http://www.w3.org/2000/svg" width="154px" height="154px">
                  <g fill="none" stroke="#22AE73" stroke-width="2">
                    <circle cx="77" cy="77" r="72" style={{ strokeDasharray: '480px, 480px', strokeDashOffset: 960 }}></circle>
                    <circle id="colored" fill="#22AE73" cx="77" cy="77" r="72" style={{ strokeDasharray: '480px, 480px', strokeDashOffset: 960 }}></circle>
                    <polyline class="st0" stroke="#fff" stroke-width="10" points="43.5,77.8 63.7,97.9 112.2,49.4 " style={{ strokeDasharray: '100px, 100px', strokeDashOffset: 200 }} />
                  </g>
                </svg>
              </div>
            </div>{isPaymentDone === true && isLoading === false && <h2 style={{ textAlign: 'center', marginTop: 60 }}>Payment successful.</h2>}{isPaymentDone === true && isLoading === false && <p style={{ textAlign: 'center', marginTop: 60 }}>You should receive your invitation email within 24 hours.</p>}{isPaymentDone === true && isLoading === false && <p style={{ textAlign: 'center', marginTop: 10 }}>If any problem, contact us by email:</p>}{isPaymentDone === true && isLoading === false && <h3 style={{ textAlign: 'center', marginTop: 20 }}>help@cheapcc.net</h3>}</div></div>
          </div>
          <div className={`faq ${faqShown ? 'shown' : ''}`}>
            <div onClick={() => (setFaqShown(!faqShown))} style={{ cursor: 'pointer', display: 'flex', flexDirection: 'row', width: '100%', alignItems: faqShown ? 'flex-start' : 'center', justifyContent: 'space-between' }}>
              <h1 className={`faq-title ${faqShown ? 'withMargin' : ''}`}>FAQ</h1>
              <svg style={{ transition: '0.1s', transform: faqShown ? 'rotate(90deg)' : '' }} fill="#000000" height="30px" width="30px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 330 330">
                <path id="XMLID_222_" d="M250.606,154.389l-150-149.996c-5.857-5.858-15.355-5.858-21.213,0.001
                c-5.857,5.858-5.857,15.355,0.001,21.213l139.393,139.39L79.393,304.394c-5.857,5.858-5.857,15.355,0.001,21.213
                C82.322,328.536,86.161,330,90,330s7.678-1.464,10.607-4.394l149.999-150.004c2.814-2.813,4.394-6.628,4.394-10.606
                C255,161.018,253.42,157.202,250.606,154.389z"/>
              </svg>
            </div>
            <div className={`faq-text ${faqShown ? 'shown' : ''}`}>
              <h3 style={{ textAlign: 'left' }}>{faqShown ? "What is CheapCC.net?" : ""}</h3>
              <p>{faqShown ? "CheapCC.net is a site where you can legally get the genuine Adobe CC All Apps plan applied to your own Adobe account, at a much lower price." : ""}</p>
              <h3 style={{ textAlign: 'left', marginTop: 20 }}>{faqShown ? "Where does the subscription come from?" : ''}</h3>
              <p style={{ marginBottom: 5 }}>{faqShown ? "The subscription is not hacked nor stolen. As you may know, Adobe offers subscriptions in bulk to companies and schools, for a fraction of the cost." : ""}</p>
              <p style={{ marginBottom: 5 }}>{faqShown ? "With CheapCC, your Adobe subscription is applied to your own account via a private invitation from educational institutions we partner with." : ""}</p>
              <h3 style={{ textAlign: 'left', marginTop: 20 }}>{faqShown ? "Why can't I place an order?" : ''}</h3>
              <p style={{ marginBottom: 5 }}>{faqShown ? "Because of the nature of these subscriptions, the number of places available is not infinite, although we do our best to offer as many as possible at the most affordable price." : ""}</p>
              <p style={{ marginBottom: 5 }}>{faqShown ? "If you can place an order, there are still places available. If you can't place an order, don't worry: new places will be available soon." : ""}</p>
              {/*<h3 style={{ textAlign: 'left', marginTop: 20 }}>{faqShown ? "Can my subscription be canceled?" : ""}</h3>*/}
              {/*<p>{faqShown ? "We guarantee the full duration of our subscriptions. Our support team is available daily to address any inquiries or issues, providing replacement subscriptions or refunds if necessary." : ""}</p>*/}
              <h3 style={{ textAlign: 'left', marginTop: 20 }}>{faqShown ? "Does anyone has access to my content?" : ""}</h3>
              <p>{faqShown ? "We DO NOT have access to your content created in the Adobe applications. Only Adobe has potential access, particularly when using online features, such as generative AI. Here's an article about the recent updates to Adobe's terms of use on their access to user content:" : ""}</p>
              {faqShown && <a style={{ textAlign: 'left', display: 'flex', marginLeft: 10, width: '100%', color: '#596B76' }} href='https://blog.adobe.com/en/publish/2024/06/06/clarification-adobe-terms-of-use'>blog.adobe.com/en/publish/2024/06/06/clarification-adobe-terms-of-use</a>}
            </div>
          </div>
        </div>
        <Footer />
      </div>}
      {(isMobile === 'true' || isMobile === true) && <div className='mobile-main'>
        <div className='mobile-main-main' ref={mobileMain}>
          {isHovered === true && (<div className='question-div' style={{ position: 'fixed', ...getSvgPosition(), zIndex: 999, width: '230px', backgroundColor: 'black', color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>If disabled, your subscription will be revoked at the end of the first period. If enabled, renewal will be automatic.<br></br>To cancel auto-pay, simply contact us.</div>)}
          <form onSubmit={handleSubmit} className={`inputs-mobile ${!clientSecret && formSubmittedAutoPay === false ? "shown" : ''}`}>
            {!clientSecret && formSubmittedAutoPay === false && <div className='inputs-subdiv-mobile'>
              <h1 style={{ marginBottom: 0, textAlign: 'left' }}>Create an order</h1>
              <p style={{ fontSize: 18, margin: 0, marginTop: 10 }}>Apply the Creative Cloud All Apps plan to your own Adobe account.</p>
              <div className='input-container-mobile'>
                <div className='input-div-mobile'>
                  <label style={{ textAlign: 'left', fontSize: 18 }}>Full name:</label>
                  <div className='input-wrapper-mobile'><input style={{ width: '-webkit-fill-available', fontSize: 18 }} value={name} spellCheck={false} required onChange={(e) => (setName(e.target.value))} placeholder='John Smith' type='name' /></div>
                </div>
                <div className='input-div-mobile'>
                  <label style={{ textAlign: 'left', fontSize: 18 }}>Adobe account email address:</label>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <div className='input-wrapper-mobile'><input style={{ width: '-webkit-fill-available', fontSize: 18 }} value={email} spellCheck={false} required onChange={(e) => (setEmail(e.target.value))} placeholder='john.smith@gmail.com' type='email' /></div>
                  </div>
                </div>
                <div className='input-div-mobile'>
                  <label style={{ textAlign: 'left', fontSize: 18 }}>Duration of your subscription:</label>
                  <select style={{ fontSize: 18, backgroundColor: 'white' }} value={duration} onChange={(e) => (setDuration(parseFloat(e.target.value)))}>
                    <option disabled>Want a 3-days free trial? Contact us.</option>
                    <option value={30}>1 month</option>
                    <option value={365}>1 year</option>
                    <option value={730}>2 years</option>
                    <option value={1095}>3 years</option>
                  </select>
                </div>
                <div className='input-div-mobile'>
                  <label style={{ textAlign: 'left', display: 'flex', alignItems: 'center', gap: 10, fontSize: 18 }}>Enable auto-pay:<svg ref={svgRef} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} style={{ cursor: 'pointer' }} fill="black" height="20" viewBox="0 0 15 15" width="20" xmlns="http://www.w3.org/2000/svg"><g fill="#black"><path d="m6.74984 10.5c0 .4142.33579.75.75.75.41422 0 .75-.3358.75-.75s-.33578-.74999-.75-.74999c-.41421 0-.75.33579-.75.74999z" /><path d="m8.94995 6.25001c0-.67789-.58507-1.325-1.45-1.325-.86492 0-1.45.64711-1.45 1.325 0 .30376-.24624.55-.55.55s-.55-.24624-.55-.55c0-1.32211 1.11493-2.425 2.55-2.425 1.43508 0 2.55005 1.10289 2.55005 2.425 0 1.10382-.73004 1.64038-1.26449 1.96506-.10264.06235-.19141.1138-.26993.1593-.15058.08727-.26359.15276-.36509.23184-.06564.05115-.09133.08341-.10001.0971l-.00054.00087c-.00224.30184-.24762.54583-.54999.54583-.30375 0-.55-.24624-.55-.55 0-.46802.28211-.77268.52446-.96151.16338-.1273.38078-.25355.56398-.35994.0641-.03723.12404-.07204.176-.10361.46555-.28282.73556-.53376.73556-1.02494z" /><path clipRule="evenodd" d="m14 7.5c0 3.5899-2.9101 6.5-6.5 6.5-3.58985 0-6.5-2.9101-6.5-6.5 0-3.58985 2.91015-6.5 6.5-6.5 3.5899 0 6.5 2.91015 6.5 6.5zm-1 0c0 3.0376-2.4624 5.5-5.5 5.5-3.03757 0-5.5-2.4624-5.5-5.5 0-3.03757 2.46243-5.5 5.5-5.5 3.0376 0 5.5 2.46243 5.5 5.5z" fillRule="evenodd" /></g></svg></label>
                  <select style={{ fontSize: 18, backgroundColor: 'white' }} value={isAutoPay} onChange={(e) => (setIsAutoPay(e.target.value === 'true' || e.target.value === true))}>
                    <option value={true}>Yes (free)</option>
                    <option value={false}>No, thanks</option>
                  </select>
                </div>
              </div>
              <div>
                <h1 style={{ textWrap: 'nowrap', width: 'fit-content' }}><span style={{ fontSize: 28 }}>{currency}</span> {prices[duration]}.00 {duration !== 30 && <span style={{ fontSize: 14, fontWeight: 400 }}>(= {currency} {duration === 30 ? 14 : (duration === 365 ? 12 : (duration === 730 ? 10 : (duration === 1095 ? 9 : '???')))}.00 /month)</span>}</h1>
                <p style={{ color: 'black', fontSize: 18 }}>You save <b>{currency}{(AdobePrices[duration] - prices[duration]).toFixed(2)}</b> compared to adobe.com prices.</p>
              </div>
              <div className='pay-button-div-mobile'>
                <button disabled={(!email || !name)} type="submit"><span id="button-text">
                  {!clientSecret && formSubmittedAutoPay === false && isLoading === true ? <div className="spinner" id="spinner"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" width={25} height={25} style={{ shapeRendering: 'auto', display: 'block', background: 'transparent', width: '21.5', height: '21.5', paddingLeft: 11, paddingRight: 11, paddingTop: -2, paddingBottom: -2 }}><g><circle strokeDasharray="136.659280431156 47.553093477052" r="29" strokeWidth="10" stroke="#ffffff" fill="none" cy="50" cx="50"><animateTransform keyTimes="0;1" values="0 50 50;360 50 50" dur="1.3513513513513513s" repeatCount="indefinite" type="rotate" attributeName="transform"></animateTransform></circle><g></g></g></svg></div> : "Select"}</span></button>
              </div>
            </div>}
          </form>
          <div className={`payment-container-mobile ${(isPaymentDone === false && clientSecret) || (formSubmittedAutoPay === true && isPaymentDone === false) ? "shown" : ''}`}>
            {(isPaymentDone === false && clientSecret && isAutoPay === false) && <Elements stripe={stripePromise} options={options}>
              <button onClick={returnToHome} className='return-button'><span style={{ transform: 'translateY(-1px)', fontSize: 16 }}>←</span>Return to home</button>
              <Wrapper mobileMain={mobileMain.current.getBoundingClientRect().top + document.documentElement.scrollTop} currency={currency} priceId={priceData[duration]} autoPay={isAutoPay} name={name} email={email} duration={duration} prices={prices} isPaymentDone={isPaymentDone} setIsPaymentDone={setIsPaymentDone} clientSecret={clientSecret} bulk={false} bulk_data={null} />
            </Elements>}
            {(isPaymentDone === false && isAutoPay === true && formSubmittedAutoPay === true) && <Elements stripe={stripePromise} options={options}>
              <button onClick={returnToHome} className='return-button'><span style={{ transform: 'translateY(-1px)', fontSize: 16 }}>←</span>Return to home</button>
              <Wrapper mobileMain={mobileMain.current.getBoundingClientRect().top + document.documentElement.scrollTop} currency={currency} priceId={priceData[duration]} autoPay={isAutoPay} name={name} email={email} duration={duration} prices={prices} isPaymentDone={isPaymentDone} setIsPaymentDone={setIsPaymentDone} bulk={false} bulk_data={null} />
            </Elements>}
          </div>
          <div className={`payment-container-done-mobile ${isPaymentDone === true && isLoading === false ? "shown" : ''}`}><div className='success-div'><div class="animation-ctn">
            <div style={{ display:'flex', width:'100%', flexDirection:'column' }}>
              <img src='/images/tick.svg' alt='tick' style={{ height:80, alignSelf:'center' }} />
            </div>
          </div>{isPaymentDone === true && isLoading === false && <h2 style={{ textAlign: 'center', marginTop: 60 }}>Payment successful.</h2>}{isPaymentDone === true && isLoading === false && <p style={{ textAlign: 'center', marginTop: 60 }}>You should receive your invitation email within 24 hours.</p>}{isPaymentDone === true && isLoading === false && <p style={{ textAlign: 'center', marginTop: 10 }}>If any problem, contact us by email:</p>}{isPaymentDone === true && isLoading === false && <h3 style={{ textAlign: 'center', marginTop: 20 }}>help@cheapcc.net</h3>}</div></div>
        </div>
        <div className='faq-mobile'>
          <h1>FAQ</h1>
          <h3 className='faq-mobile-title'>What is CheapCC?</h3>
          <p>CheapCC.net is a site where you can legally get the genuine Adobe CC All Apps plan applied to your own Adobe account, at a much lower price.</p>
          <h3 className='faq-mobile-title'>What is included?</h3>
          <p>Here's the full list of what is included: all Adobe applications (except Substance 3D), 80GB cloud storage, Firefly AI with 250 credits renewed every month, access to beta apps, and 2 active simultaneous desktop sessions + with 2 mobile sessions. It's the authentic and official subscription, no need to use a VPN.</p>
          <h3 className='faq-mobile-title'>Where does the subscription come from?</h3>
          <p>The subscription is not hacked nor stolen. As you may know, Adobe offers subscriptions in bulk to companies and schools, for a fraction of the cost.</p>
          <p>With CheapCC, your Adobe subscription is applied to your own account via a private invitation from educational institutions we partner with.</p>
          <h3 className='faq-mobile-title'>Why can't I place an order?</h3>
          <p>Because of the nature of these subscriptions, the number of places available is not infinite, although we do our best to offer as many as possible at the most affordable price.</p>
          <p>If you can place an order, there are still places available. If you can't place an order, don't worry: new places will be available soon.</p>
          {/*<h3 className='faq-mobile-title'>Can my subscription be canceled?</h3>*/}
          {/*<p>We guarantee the full duration of our subscriptions. Our support team is available daily to address any inquiries or issues, providing replacement subscriptions or refunds if necessary.</p>*/}
          {/*<Link style={{ color: '#596b76' }} to='refund-policy'>See our refund policy</Link>*/}
          <h3 className='faq-mobile-title' style={{ marginTop: 30 }}>Does anyone has access to my content?</h3>
          <p>We DO NOT have access to your content created in the Adobe applications. Only Adobe has potential access, particularly when using online features, such as generative AI. Here's an article about the recent updates to Adobe's terms of use on their access to user content:</p>
          <a style={{ color: '#596b76', maxWidth:'-webkit-fill-available' }} target='_blank' rel="noreferrer" href='https://blog.adobe.com/en/publish/2024/06/06/clarification-adobe-terms-of-use'>blog.adobe.com/en/publish/2024/06/06/clarification-adobe-terms-of-use</a>
        </div>
        <Footer style={{ width: 'calc(100% - 100px)', marginTop: 20, marginBottom: 8 }} mobile={true} />
      </div>}
    </div>
  );
}

export default Main;
