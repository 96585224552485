import './App.css';
import React from 'react';
import { useMediaQuery } from '@react-hook/media-query';
import Footer from './components/Footer'

function Success() {
  const isMobile = useMediaQuery('(max-width: 668px)');
  return (
    <div className="App">
      <div className='logo-div'>
        <img style={{ display: 'flex', width: '100%', justifyContent: 'center' }} draggable={false} src='/images/cheapcc.png' className='logo' alt='cheapcc-logo' />
      </div>
      {(isMobile === 'false' || isMobile === false) && <div className='main'>
        <div className='main-main'>
          <div className='main-main-main'>
            <div className={`payment-container-done shown`} style={{ transform:'translateX(0px)' }}><div className='success-div'><div class="animation-ctn">
              <div class="icon icon--order-success svg">
                <svg xmlns="http://www.w3.org/2000/svg" width="154px" height="154px">
                  <g fill="none" stroke="#22AE73" stroke-width="2">
                    <circle cx="77" cy="77" r="72" style={{ strokeDasharray: '480px, 480px', strokeDashOffset: 960 }}></circle>
                    <circle id="colored" fill="#22AE73" cx="77" cy="77" r="72" style={{ strokeDasharray: '480px, 480px', strokeDashOffset: 960 }}></circle>
                    <polyline class="st0" stroke="#fff" stroke-width="10" points="43.5,77.8 63.7,97.9 112.2,49.4 " style={{ strokeDasharray: '100px, 100px', strokeDashOffset: 200 }} />
                  </g>
                </svg>
              </div>
            </div>{true && <h2 style={{ textAlign: 'center', marginTop: 60 }}>Payment successful.</h2>}{true && <p style={{ textAlign: 'center', marginTop: 60 }}>You should receive your invitation email within 24 hours.</p>}{true && <p style={{ textAlign: 'center', marginTop: 10 }}>If any problem, contact us by email:</p>}{true && <h3 style={{ textAlign: 'center', marginTop: 20 }}>help@cheapcc.net</h3>}</div></div>
          </div>
        </div>
        <Footer />
      </div>}
      {(isMobile === 'true' || isMobile === true) && <div className='mobile-main'>
        <div className='mobile-main-main'>
          <div className={`payment-container-done-mobile ${true ? "shown" : ''}`}><div className='success-div'><div class="animation-ctn">
            <div style={{ display:'flex', width:'100%', flexDirection:'column' }}>
              <img src='/images/tick.svg' alt='tick' style={{ height:80, alignSelf:'center' }} />
            </div>
          </div>{true && <h2 style={{ textAlign: 'center', marginTop: 60 }}>Payment successful.</h2>}{true && <p style={{ textAlign: 'center', marginTop: 60 }}>You should receive your invitation email within 24 hours.</p>}{true && <p style={{ textAlign: 'center', marginTop: 10 }}>If any problem, contact us by email:</p>}{true && <h3 style={{ textAlign: 'center', marginTop: 20 }}>help@cheapcc.net</h3>}</div></div>
        </div>
        <Footer style={{ width: 'calc(100% - 100px)', marginTop: 20, marginBottom: 8 }} mobile={true} />
      </div>}
    </div>
  );
}

export default Success;
