import { CardCvcElement, CardExpiryElement, CardNumberElement, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import React, { useState, useEffect } from 'react';
import { useMediaQuery } from '@react-hook/media-query';

const Wrapper = (props) => {
    const { setIsPaymentDone } = props;
    const { email } = props;
    const { bulk } = props;
    const { totalPrice } = props;
    const { name } = props;
    const { autoPay } = props;
    const { orders } = props;
    const { currency } = props;
    const { duration } = props;
    const { mobileMain } = props;
    const { prices } = props;
    const { priceId } = props;
    const { clientSecret } = props;
    const stripe = useStripe();
    const elements = useElements()
    const [message, setMessage] = useState(null);
    const [isPaymentLoading, setIsPaymentLoading] = useState(false);
    const isMobile = useMediaQuery('(max-width: 668px)');

    useEffect(() => {
        if (isMobile===true || isMobile==='true') {
            window.scrollTo({ top: mobileMain-15, behavior: 'smooth' });
        }
      }, [isMobile, mobileMain])

    useEffect(() => {

        if (!stripe) {
            return;
        }
        if ((autoPay === false || autoPay==='false') && (bulk===false || bulk==='false') ) {

            stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
                switch (paymentIntent.status) {
                    case "succeeded":
                        setMessage("Payment succeeded!");
                        break;
                    case "processing":
                        setMessage("Your payment is processing.");
                        break;
                    case "requires_payment_method":
                        // setMessage("Your payment was not successful, please try again.");
                        break;
                    default:
                        setMessage("Something went wrong.");
                        break;
                }
            });
        }
    }, [stripe, autoPay, clientSecret, bulk]);

    const createSubscription = async (e) => {
        setIsPaymentLoading(true)
        try {
            const paymentMethod = await stripe.createPaymentMethod({
                card: elements.getElement(CardNumberElement),
                type: "card",
            });
            const response = await fetch('/.netlify/functions/create-subscription', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    name,
                    email,
                    duration,
                    paymentMethod: paymentMethod.paymentMethod,
                    priceId: priceId,
                    currency:currency
                })
            })
            if (!response.ok) return setMessage("Payment unsuccessful!");
            const data = await response.json();
            const confirm = await stripe.confirmCardPayment(data.clientSecret);
            if (confirm.error) {
                setMessage("Payment unsuccessful!");
                setIsPaymentLoading(false)
            }
            setMessage("Payment Successful! Subscription active.");
            setIsPaymentDone(true);
            setIsPaymentLoading(false);
        } catch (err) {
            console.error(err);
            setMessage("Payment failed! " + err.message);
            setIsPaymentLoading(false)
        }
    };

    const handlePaymentSubmit = async (e) => {

        elements.submit()
        e.preventDefault();

        if (!stripe) {
            return;
        }

        setIsPaymentLoading(true);

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: "https://cheapcc.net/success",
            },
            redirect: 'if_required',
        });

        if (error) {
            setIsPaymentDone(false);
            if (error.type === "card_error" || error.type === "validation_error") {
                setMessage(error.message);
            } else {
                setMessage("An unexpected error occurred.");
            }
        }
        else {
            setMessage("Payment successful!");
            setIsPaymentLoading(true);
            if (bulk===true || bulk==='false') {
                const response = await fetch('/.netlify/functions/send-webhook', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        orders:orders,
                    })
                })
                if (!response.ok) return setMessage("Problem while sending bulk orders.");
                else { setIsPaymentDone(true) }
            }
            setIsPaymentDone(true)
        }

        setIsPaymentLoading(false);
    };

    const paymentElementOptions = {
        layout: "tabs",
        appearence: {
            theme: 'stripe',
        },
        business: { name: 'ASM' }
    }

    return (
        <div className="payment-form">
            {autoPay === false && <PaymentElement id="payment-element" options={paymentElementOptions} />}
            {autoPay === true && <div className='payment_wrapper' style={{ gap: 15, display: 'flex' }}><div className='card-wrapper'><h4 style={{ textWrap: 'nowrap', fontSize:14, width: 'fit-content', margin: 0, marginBottom:10, fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif', color: '#30313D' }}>Card number</h4><CardNumberElement options={{ showIcon: true, classes: { base: 'cardElement number', focus:'cardElement focus' }, style: { base: { ':focus': { 'background-color': 'black' } } } }} /></div><div className='payment_subwrapper' style={{ display: 'flex', flexDirection: 'row', gap: 15 }}><div className='card-subwrapper'><h4 style={{ textWrap: 'nowrap', fontSize:14, width: 'fit-content', margin: 0, marginBottom:10, fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif', color: '#30313D' }}>Expiry date</h4><CardExpiryElement options={{ showIcon: true, classes: { base: 'cardElement expiry', focus:'cardElement focus' } }} /></div><div className='card-subwrapper'><h4 style={{ textWrap: 'nowrap', fontSize:14, width: 'fit-content', margin: 0, marginBottom:10, fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif', color: '#30313D' }}>CVV code</h4><CardCvcElement options={{ showIcon: true, classes: { base: 'cardElement cvv', focus:'cardElement focus' } }} /></div></div></div>}
            <div style={{ marginTop: 50 }}>
                <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 15 }}>
                    <p id={message} style={{ textWrap: 'nowrap', width: 'fit-content', margin: 0, fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif', color: '#30313D' }}>E-mail:</p>
                    <p style={{ textWrap: 'nowrap', width: 'fit-content', margin: 0, fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif', color: '#30313D' }}>{email}</p>
                </div>
                <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 15 }}>
                    <p style={{ textWrap: 'nowrap', width: 'fit-content', margin: 0, fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif', color: '#30313D' }}>Name:</p>
                    <p style={{ textWrap: 'nowrap', width: 'fit-content', margin: 0, fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif', color: '#30313D' }}>{name}</p>
                </div>
                <div className='bar'></div>
                <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 15 }}>
                    <p style={{ textWrap: 'nowrap', width: 'fit-content', margin: 0, fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif', color: '#30313D' }}>{isMobile?'Subtotal:':'Creative Cloud All Apps - Group'}</p>
                    <p style={{ textWrap: 'nowrap', width: 'fit-content', margin: 0, fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif', color: '#30313D' }}>{currency}{bulk===false?(prices[duration]).toFixed(2):(totalPrice).toFixed(2)} {autoPay === true ? (duration === 30 ? 'billed monthly' : (duration === 365 ? 'billed yearly' : (duration === 730 ? 'billed bi-yearly' : (duration === 1095 ? "billed tri-yearly" : "")))) : ('(one-time payment)')}</p>
                </div>
                <div className='bar'></div>
                <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <h3 style={{ fontWeight: 600, textWrap: 'nowrap', width: 'fit-content', fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif', color: '#30313D' }}>Total (tax included):</h3>
                    <h3 style={{ fontWeight: 600, textWrap: 'nowrap', width: 'fit-content', fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif', color: '#30313D' }}>{currency}{bulk===false?(prices[duration]).toFixed(2):(totalPrice).toFixed(2)}</h3>
                </div>
            </div>

            <p style={{ margin: '40px 0px' }}>You will instantly receive a confirmation e-mail. When your Adobe subscription is delivered, a second e-mail containing all instructions will be sent to your inbox. The delay is up to 24 hours, but usually takes a few minutes.</p>

            <div style={{ display: 'flex', flexDirection: 'column', marginTop: 20 }}><button onClick={autoPay === true ? createSubscription : handlePaymentSubmit} className="pay-now" style={{ cursor: 'pointer', fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif', fontSize: '18px', marginTop: 30, padding: '10px 20px', alignSelf: 'center', justifySelf: 'center' }} disabled={isPaymentLoading === true || !stripe} target="_blank" rel="noreferrer noopener" type="submit">
                <span>
                    {isPaymentLoading === true ? <div className="spinner" id="spinner"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" width={25} height={25} style={{ shapeRendering: 'auto', display: 'block', background: 'transparent', width: '25', height: '25' }}><g><circle strokeDasharray="136.659280431156 47.553093477052" r="29" strokeWidth="10" stroke="#ffffff" fill="none" cy="50" cx="50"><animateTransform keyTimes="0;1" values="0 50 50;360 50 50" dur="1.3513513513513513s" repeatCount="indefinite" type="rotate" attributeName="transform"></animateTransform></circle><g></g></g></svg></div> : "Pay now"}</span>
            </button>
                {/* {message && <div style={{ alignSelf: 'center', textAlign: 'center', marginTop: 20, color: 'red' }} id="payment-message">{message}</div>} */}
                <img draggable={false} src='/images/badge.png' style={{ width: '250px', marginTop: 30, userSelect: 'none', alignSelf: 'center', justifySelf: 'center' }} alt='stripe-badge' />
            </div>
        </div>)
}
export default Wrapper
